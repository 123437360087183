#map {
  height: 100%;
  width: 50%;
}
#streetview {
  height: 100%;
  width: 50%;
}
#root {
  height: 100%;
  width: 100%;
  display: flex;
}
body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 8px;
  box-sizing: border-box;
}
